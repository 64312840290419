.partners{
    padding-top: 73px;
    padding-bottom: 65px;
    &__logos{
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 15px;
        flex-wrap: wrap;
        
        img{
            width: 150px;
            height: 150px;
        }
    }
}
.titleb{
    padding-bottom: 17px;
}
@media (max-width:1400px){
    .partners__logos {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        gap: 15px;
        flex-wrap: wrap;
    }
}