.header {
    padding: 0 15px;
    padding-top: 32px;
    padding-bottom: 40px;

    &-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 20px;
    }
    .logo {
        margin-right: 36px;
        width: 170px;
        height: 40px;
    }

    .one {
        display: flex;
        align-items: center;
    }
    .search {
        width: 23px;
        height: 23px;
        button{
            background: none;
        }
        svg {
            width: 100%;
            height: 100%;
        }
    }
    nav {
        ul {
            display: flex;
            align-items: center;
            gap: 30px;
            li a {
                font-weight: 500;
                font-size: 18px;
                line-height: 19px;
                transition: 0.2s;
                color: #505457;
            }
            li a:hover {
                color: #4298c8;
                transition: 0.2s;
            }
            li a.active {
                color: #4298c8;
            }
        }
    }
    .search {
        img {
            width: 23px;
            height: 23px;
        }
    }
    .tel {
        a {
            font-weight: 600;
            font-size: 18px;
            line-height: 19px;
            /* identical to box height, or 106% */

            display: flex;
            align-items: center;
            letter-spacing: -0.03em;
            color: #728d9b;
            transition: 0.3s;
        }
        a:hover {
            transition: 0.3s;
            color: #505457;
        }
    }
    .lk {
        display: flex;
        align-items: center;
        gap: 7px;
        font-weight: 500;
        font-size: 18px;
        line-height: 19px;
        /* identical to box height, or 106% */
        transition: 0.3s;
        display: flex;
        align-items: center;
        letter-spacing: -0.03em;

        color: #728d9b;

        img {
            width: 21px;
            height: 23px;
        }
    }
    .lk:hover {
        filter: invert(57%) sepia(77%) saturate(385%) hue-rotate(160deg) brightness(82%) contrast(90%);
        transition: 0.3s;
    }
    .burger1200 {
        display: none;
    }
    .burgermob {
        display: none;
    }
}
@media (max-width: 920px) {
    .tel {
        display: none;
    }
    .header .lk {
        display: none !important;
    }
    .header .call-blue {
        padding: 10px 10px 10px 12px !important;
        display: flex;
        align-items: center;
        gap: 14px !important;
        background: #3b9dd3;
        border-radius: 10px;
        font-weight: 600;
        font-size: 14px !important;
        line-height: 14px !important;
        transition: 0.2s;
        text-align: center;
        letter-spacing: -0.02em;
        color: #ffffff;
        display: none;
    }
    nav {
        display: none;
    }
    .burgermob {
        width: 32px;
        height: 32px;
        display: block !important;
    }
    button {
        background: none;
    }
    .search {
        width: 32px !important;
        height: 32px !important;
    }
    .header .logo {
        margin-right: 0;
        width: 115px;
        height: 26px;
    }
    .cargo-wrapper {
        display: flex;
        justify-content: center !important;
        align-items: center;
        flex-direction: column;
        gap: 35px;
    }
    .cargo__text h1 {
        max-width: 343px !important;

        margin-bottom: 0px !important;
        color: #ffffff;
        font-weight: 600 !important;
        font-size: 36px !important;
        line-height: 104% !important;
        /* or 37px */

        letter-spacing: -1.5px !important;

        color: #ffffff;
        span {
            font-style: italic !important;
            font-weight: 800 !important;
            font-size: 36px !important;
        }
    }
    .cargo__text h2 {
        font-size: 22px;
        line-height: 114.5%;
        max-width: 300px;
        letter-spacing: -1.5px;
        margin-bottom: 16px !important;
        color: #ffffff;
    }
    .call-white {
        padding: 14px 14px 14px 16px !important;
        display: flex;
        align-items: center;
        gap: 27px !important;
        background: #ffffff;
        border-radius: 10px;
        font-weight: 600;
        font-size: 19px !important;
        line-height: 19px !important;
        /* or 100% */

        text-align: center;
        letter-spacing: -0.02em !important;

        color: #3494c8;
    }
    .cargo__img {
        width: 343px !important;
        height: auto;
        border-radius: 20px;
        margin-bottom: -100px;
    }
    .cargo {
        padding-bottom: 50px !important;
        margin-bottom: 100px !important;
    }
    .advantages__item {
        background: #ffffff;
        border-radius: 17px;
        position: relative;
        width: 340px !important;
        height: auto !important;
        gap: 30px;
        display: flex;
        justify-content: space-between;
        flex-direction: column-reverse;
    }
    .advantages__item .extrabox {
        position: absolute;
        left: auto !important;
        right: 20px ;
        top: 22px;
        svg{
            width: 44px;
            height: 44px;
        }
    }
    .advantages__item .imgbox {
        width: 100%;
        max-width: 340px !important;
        height: 355px;
    }
    .advantages__item .textbox {
        margin-right: 80px !important;
        
    }
    .advantages .right .imgbox {
        height: 158px !important;
    }
    .header .container{
        padding:0 15px !important; 
    }
}
@media (max-width: 1200px) {
    .burger1200 {
        background: none;
        display: block !important;
        margin-right: 60px;
        position: relative;
    }

    .burger1200:hover .menu1200 {
        display: flex !important;
        flex-direction: column;
        position: absolute;
        left: -70px;
        top: 100%;
        transform: scale(1);
        transition: 0.2s;
        padding: 20px;
        width: 160px;
        background: #3b9ed3;
        border-radius: 10px;
        z-index: 99;
        a {
            font-weight: 500;
            font-size: 18px;
            line-height: 19px;
            transition: 0.2s;
            color: #fff !important;
        }
    }
    .menu1200 {
        transform: scale(0);
        transition: 0.2s;
        position: absolute;
        display: flex !important;
        flex-direction: column;
        position: absolute;
        left: -70px;
        top: 100%;

        transition: 0.2s;
        padding: 20px;
        width: 160px;
        background: #3b9ed3;
        border-radius: 10px;
        z-index: 99;
        a {
            font-weight: 500;
            font-size: 18px;
            line-height: 19px;
            transition: 0.2s;
            color: #fff !important;
        }
    }
    nav {
        position: relative;
    }
}


@media (max-width: 1400px) {
    .header nav ul li a {
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        transition: 0.2s;
        color: #505457;
    }
    .search {
        width: 17px !important;
        height: 17px !important;
    }
    .header .tel a {
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        display: flex;
        align-items: center;
        letter-spacing: -0.03em;
        color: #728d9b;
        transition: 0.3s;
    }
    .header .lk {
        display: flex;
        align-items: center;
        gap: 7px;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        transition: 0.3s;
        display: flex;
        align-items: center;
        letter-spacing: -0.03em;
        color: #728d9b;
        svg {
            width: 17px;
            height: 17px;
        }
    }
}