.burger {
    position: fixed;
    width: 100%;
    height: 100vh;
    background: #ffffff;
    z-index: 999;
    left: 0;
    top: 0;

    padding-bottom: 48px;
    &-close {
        margin-bottom: 46px;
        background: none;
        width: 32px;
        height: 32px;
        margin-left: 30px;
        margin-top: 32px;
        img {
            width: 100%;
            height: 100%;
        }
    }
    &__body {
        ul {
            display: flex;
            flex-direction: column;
            gap: 30px;
            padding-left: 30px;
            a {
                font-weight: 600;
                font-size: 20px;
                line-height: 19px;
                /* identical to box height, or 95% */

                display: flex;
                align-items: center;

                color: #505457;
            }
            a.active {
                color: #439aca;
            }
        }
    }
    &__joinlk a {
        margin-top: 32px;
        border-top: 1px solid #e9ecef;
        border-bottom: 1px solid #e9ecef;
        padding: 30px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 9px;
        font-weight: 600;
        font-size: 20px;
        line-height: 19px;
        color: #728d9b;
    }
    &__bottom {
        position: absolute;
        left: 0;
        bottom: 40px;
        padding: 0 24px;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 12px;
        &-tel {
            background: #ffffff;
            box-shadow: 0px 4px 41px rgba(0, 0, 0, 0.05);
            border-radius: 10px;
            

            a {
                padding: 17px 26px;
                display: flex;
                align-items: center;
                gap: 11px;
                font-weight: 600;
                font-size: 21px;
                line-height: 17px;
                /* identical to box height, or 81% */

                letter-spacing: -0.01em;

                color: #505457;
            }
        }
    }
    .call-blue {
        display: flex !important;
        justify-content: space-between;
        width: 100%;
        padding: 14px 14px 14px 16px !important;
    }
}
