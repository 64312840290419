@media (max-width:920px){
    .workmob{
        background: url(../../img/workmob-bg.svg) no-repeat;
        background-color: #44A1D5;
        background-size: 100%;
        background-position: top center;
    }
    .work__data-text {
        display: flex;
        gap: 14px;
        align-items: center;
        background: none;
        border-radius: 75px;
        padding: 15px 0;
        margin: 0 0px;
    }
    .work__data-text span {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        border: 2px solid #ffffff;
        border-radius: 50%;
        font-weight: 500;
        font-size: 18px;
        line-height: 19px;
        text-align: center;
        letter-spacing: -0.03em;
        color: #ffffff;
    }
    .work__data-text p {
        font-size: 19px;
        line-height: 19px;
        letter-spacing: -0.02em;
        color: #ffffff;
    }
    .work__data-text p b {
        font-style: italic;
        font-weight: 700;
        font-size: 19px;
        line-height: 19px;
        display: flex;
        align-items: center;
        letter-spacing: -0.02em;
        color: #ffffff;
    }
    .last{
        padding-bottom: 30px;
    }
    .workmob__bottom{
        img{
            width: 311px;
            margin-left: 10px;
            display: block;
            margin-top: -50px;
        }
        span{
            font-weight: 600;
            font-size: 19px;
            line-height: 114.5%;
            /* or 22px */
            display: block;
            letter-spacing: -1px;
            width: 280px;
            margin-top: 28px;
            padding-bottom: 42px;
            color: #FFFFFF;
        }
    }
}
@media (min-width:422px){
    .workmob{
        background: url(../../img/workmob-bg.svg) no-repeat;
        background-color: #44A1D5;
        background-size: 100%;
        background-position: top -100px center;
    }
}
@media (min-width:478px){
    .workmob{
        background: url(../../img/workmob-bg.svg) no-repeat;
        background-color: #44A1D5;
        background-size: 100%;
        background-position: top -160px center;
    }
}
@media (min-width:530px){
    .workmob{
        background: url(../../img/workmob-bg.svg) no-repeat;
        background-color: #44A1D5;
        background-size: 100%;
        background-position: top -300px center;
    }
}
@media (min-width:580px){
    .workmob{
        background: url(../../img/workmob-bg.svg) no-repeat;
        background-color: #44A1D5;
        background-size: 100%;
        background-position: top -340px center;
    }
}
@media (min-width:630px){
    .workmob{
        background: url(../../img/workmob-bg.svg) no-repeat;
        background-color: #44A1D5;
        background-size: 100%;
        background-position: top -420px center;
    }
}
@media (min-width:680px){
    .workmob{
        background: url(../../img/workmob-bg.svg) no-repeat;
        background-color: #44A1D5;
        background-size: 100%;
        background-position: top -500px center;
    }
}
@media (min-width:748px){
    .workmob{
        background: url(../../img/workmob-bg.svg) no-repeat;
        background-color: #44A1D5;
        background-size: 100%;
        background-position: top -590px center;
    }
}
@media (min-width:798px){
    .workmob{
        background: url(../../img/workmob-bg.svg) no-repeat;
        background-color: #44A1D5;
        background-size: 100%;
        background-position: top -640px center;
    }
}
@media (min-width:868px){
    .workmob{
        background: url(../../img/workmob-bg.svg) no-repeat;
        background-color: #44A1D5;
        background-size: 100%;
        background-position: top -730px center;
    }
}