.titlew {
    padding-top: 85px;
    color: #ffffff;
    padding-bottom: 60px;
}
.work {
    background: url(../../img/work-bg.svg);
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 83px;
    &__cards {
        display: flex;
        
        gap: 68px;
        height: 260px;
        position: relative;
        .road {
            width: 920px;
            position: absolute;
            left: 190px;
            top: 40px;
        }
    }
    &__card {
        background: #f9f9f9;
        border-radius: 17px;
        padding-bottom: 26px;
    }
    .work1 {
        width: 178px;
        filter: drop-shadow(15px 4px 39px rgba(12, 132, 200, 0.15));
        margin-top: -34px;
        margin-bottom: 12px;
        margin-left: 60px;
        margin-right: 51px;
        img {
            width: 100%;
            height: 100%;
        }
    }
    .work2 {
        width: 237px;
        filter: drop-shadow(15px 4px 39px rgba(12, 132, 200, 0.15));
        margin-top: -38px;
        margin-bottom: 28px;
        margin-left: 26px;
        margin-right: 27px;
        img {
            width: 100%;
            height: 100%;
        }
    }
    .work3 {
        width: 244px;
        filter: drop-shadow(15px 4px 39px rgba(12, 132, 200, 0.15));
        margin-top: -42px;
        margin-bottom: 35px;
        margin-left: 18px;
        margin-right: 28px;
        img {
            width: 100%;
            height: 100%;
        }
    }
    &__data {
        &-text {
            display: flex;
            gap: 10px;
            align-items: center;
            background: #439aca;
            border-radius: 75px;
            padding: 13px;
            margin: 0 20px;
            span {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 37px;
                height: 37px;
                border: 2px solid #ffffff;
                border-radius: 50%;
                font-weight: 500;
                font-size: 18px;
                line-height: 19px;
                /* identical to box height, or 106% */

                text-align: center;
                letter-spacing: -0.03em;

                color: #ffffff;
            }
            p {
                font-size: 17px;
                line-height: 19px;
                letter-spacing: -0.02em;

                color: #ffffff;
                b {
                    font-style: italic;
                    font-weight: 700;
                    font-size: 17px;
                    line-height: 19px;
                    /* or 112% */

                    display: flex;
                    align-items: center;
                    letter-spacing: -0.02em;

                    color: #ffffff;
                }
            }
        }
    }
    &__carde {
        z-index: 1;
        position: relative;
        right: 0;
        top: -100px;
        svg {
            width: 360px;
        }
        span {
            position: absolute;
            bottom: -130px;
            left: 30px;
            font-weight: 600;
            font-size: 19px;
            line-height: 114.5%;
            /* or 22px */

            letter-spacing: -1px;
            max-width: 229px;
            color: #ffffff;
            b {
                font-style: italic;
                font-weight: 700;
                font-size: 19px;
                line-height: 114.5%;
                /* or 22px */

                letter-spacing: -1px;

                color: #ffffff;
            }
        }
        img {
            position: absolute;
            left: 29px;
            top: -53px;
            filter: drop-shadow(10px 4px 54px rgba(9, 76, 113, 0.35));
        }
    }
}
@media (max-width:1400px){
    .work__cards {
        display: flex;
        gap: 170px 60px;
        padding: 0 30px;
        height: auto;
        position: relative;
        flex-wrap: wrap;
        justify-content: center;
    }
    .work__cards .road {
        display: none;
    }
    .work__carde span {
        position: absolute;
        bottom: 50px;
        left: 30px;
        font-weight: 600;
        font-size: 19px;
        line-height: 114.5%;
        letter-spacing: -1px;
        max-width: 229px;
        color: #ffffff;
    }
    .work__card {
        background: #f9f9f9;
        border-radius: 17px;
        padding-bottom: 26px;
        height: 280px;
    }
    .work {
        
        padding-bottom: 0px;
    }
}