
.advantages {
    padding-bottom: 93px;
    &__list {
        display: flex;
        gap: 20px 35px;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        
        
        .advantage1{
            .imgbox{
                background: url(../../img/advantage1.png);
                object-fit: cover;
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }

        }
        .advantage2{
            .imgbox{
                background: url(../../img/advantage2.png);
                object-fit: cover;
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }

        }
        .advantage3{
            .imgbox{
                background: url(../../img/advantage3.png);
                object-fit: contain;
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }

        }
        .advantage4{
            height: 240px;
            .imgbox{
                background: url(../../img/advantage4.png);
                object-fit: cover;
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }

        }
        .advantage5{
            height: 240px;
            .imgbox{
                background: url(../../img/advantage5.png);
                object-fit: cover;
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }

        }
        
        
    }
    .right{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 20px;
        .imgbox{
            height: 145px;
        }
    }
    
    &__item {
        background: #ffffff;
        box-shadow: 0px 4px 41px rgba(0, 0, 0, 0.05);
        border-radius: 17px;
        position: relative;
        width: 320px;
        height: 500px;
        .extrabox{
            position: absolute;
            left: 11px;
            top: 22px;
            img{
                width: 58px;
                height: 58px;
            }
        }
        .textbox {
            font-weight: 500;
            font-size: 21px;
            line-height: 114.5%;
            /* or 24px */
            
            letter-spacing: -1px;

            color: #505457;
            span {
                font-style: italic;
                font-weight: 700;
            }
            margin-top: 27px;
            margin-left: 29px;
            margin-right: 27px;
        }
        .imgbox {
            width: 100%;
            max-width: 320px;
            height: 355px;
            img {
                width: 100%;
                
                height: auto;
                object-fit: contain;
            }
        }
    }
}
@media (max-width:1400px){
    .container{
        padding-left: 20px;
        padding-right: 20px;
    }
    .advantages__list{
        justify-content: space-evenly;
    }
    .advantage4{
        height: 500px;
        .imgbox{
            background: url(../../img/advantage4.png);
            object-fit: cover;
            background-repeat: no-repeat;
        }

    }
    .advantage5{
        height: 500px;
        .imgbox{
            background: url(../../img/advantage5.png);
            object-fit: cover;
            background-repeat: no-repeat;
        }

    }
}
@media(max-width:920px){
    .advantages .title{
        font-weight: 700;
        font-size: 36px;
        line-height: 104%;
        /* or 37px */
        
        letter-spacing: -1.5px;
        
        color: #505457;
    }
}